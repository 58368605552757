(function ($, window, namespace) {
  // 通过gulp自动引入静态资源
  var footerHtml = `<div class="hr-public-foot" style="background-color: #fcfcfc; border-top: #ededed solid 1px; color: #555; font-size: 14px; line-height: 28px; overflow: hidden; padding: 35px 0 30px; text-align: center; width: 100%"><a class="more" href="//hr.xishanju.com" target="_blank" style="background: none; color: #b2b2b2; display: inline-block; padding: 0 20px; text-decoration: none">了解更多</a> <a href="//www.xishanju.com" target="_blank" style="background: url(//zhcdn01.xoyo.com/xassets/com/hr/top/images/line.png) right 6px no-repeat; color: #666; display: inline-block; padding: 0 20px; text-decoration: none">西山居官网</a> <a class="nobg" href="//art.xishanju.com/" target="_blank" style="background: none; color: #666; display: inline-block; padding: 0 20px; text-decoration: none">西山居美术中心</a></div>`;

  // 若存在以下ID element元素, 默认footer会append到中
  var DEFAULT_APPEND_TO = '__PUBLIC_FOOTER__';

  // 定义存储名， 该存储命名以__PUBLIC_FOOTER__作为约定命名空间
  // 在以后版本中，如果需要针对Storage进行管理，如清理缓存，可以根据以__PUBLIC_FOOTER__开头遍历清理即可，所以请谨慎命名
  var STORAGE_NAME = '__PUBLIC_FOOTER__YEAR';

  // 是否开启调试模式
  var debug = false;

  /**
   * 从客户端获取年份
   */
  function getCurrentYearFromClient() {
    return new Date().getFullYear();
  }

  /**
   * 获取url参数
   */
  function getQueryStringArgs (){
    var qs = (location.search.length > 0 ? location.search.substring(1) : ""),
      args = {},
      items = qs.length ? qs.split("&") : [],
      item = null,
      name = null,
      value = null,
      i = 0,
      len = items.length;
    for (i=0; i < len; i++){

      item = items[i].split("=");

      name = decodeURIComponent(item[0]);

      value = decodeURIComponent(item[1]);

      if (name.length){

        args[name] = value;

      }

    }

    return args;

  }

  /**
   * 是否支持LocalStorage
   */
  function isLocalStorageSupported() {
    return typeof localStorage !== 'undefined';
  }

  /**
   * process 统计模块
   */
  function processStatisticModule() {
    var web_l = (window.location.hostname) || '';
    var tongji_js = '';
    if (web_l != '') {
      web_l = web_l.split('.').join('');
      if (web_l.lastIndexOf('hrxishanjucom') > -1 ) { //西山居hr
        tongji_js = [
          '<div style="display:none">',
          '<!-- 西山居hr统计代码 -->',
          '<script type="text/javascript">',
          'var _hmt = _hmt || [];',
          '(function() {',
          'var hm = document.createElement("script");',
          'hm.src = "https://hm.baidu.com/hm.js?698f56b8b4ec8734729750b5f6531d95";',
          'var s = document.getElementsByTagName("script")[0];',
          's.parentNode.insertBefore(hm, s);',
          '})();',
          '</script>',
          '</div>'
        ].join('');
        document.write(tongji_js);
      }
      else if (web_l.lastIndexOf('lmxoyocom') > -1) { //猎魔
        tongji_js = [
          '<div style="display:none">',
          '<!-- 猎魔军团 统计代码 -->',
          '<script type="text/javascript">',
          'var _hmt = _hmt || [];',
          '(function() {',
          'var hm = document.createElement("script");',
          'hm.src = "//hm.baidu.com/hm.js?1cc4c83a6a933cbab7ddce4fe3c07693";',
          'var s = document.getElementsByTagName("script")[0];',
          's.parentNode.insertBefore(hm, s);',
          '})();',
          '</script>',
          '</div>'
        ].join('');
        document.write(tongji_js);
      }
    }
  }

  /**
   * 获取将要附加的节点，默认是body，允许外部修改。
   * @returns {*|HTMLElement}
   */
  function getAppendToDomId() {
    // 如果发现有footer指定的参数
    var namespaceFooter = namespace.footer;
    if (namespaceFooter && namespaceFooter.appendTo) {
      // 优先配置项
      return $(namespaceFooter.appendTo);
    }
    // 默认append到指定位置
    var $defaultAppendTo = $(`#${DEFAULT_APPEND_TO}`);
    // 判断默认节点是否存在
    if ($defaultAppendTo.length) {
      return $defaultAppendTo;
    }
    // 当不存在参数值且默认元素无法找到时, append 到body
    return $('body');
  }

  (function () {
    // 确定加入到哪个节点
    var $appendToDom = getAppendToDomId();
    // 客户端年份
    var clientCurrentYear = getCurrentYearFromClient();
    // 缓存年份
    // 先使用客户端年份作为copy right时间，并替换到模板中。 这样能保证百分之99的用户是没问题的。 后续也会再次校验服务端时间并更新即可。
    footerHtml = footerHtml.replace('{CURRENT_YEAR}', clientCurrentYear);
    // 将footer博班加入指定节点中
    $appendToDom.append(footerHtml);
    // process 统计模块
    processStatisticModule();
  }());
}(jQuery, window, window.__PUBLIC_MODULE__ = window.__PUBLIC_MODULE__ || {}));
